var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect } from 'react';
import { fetchLiveList } from 'Duck/sessions';
import { connect } from 'react-redux';
import { NoContent, Loader, LoadMoreButton } from 'UI';
import SessionItem from 'Shared/SessionItem';
import withPermissions from 'HOCs/withPermissions';
import { KEYS } from 'Types/filter/customFilter';
import { applyFilter, addAttribute } from 'Duck/filters';
import { FilterCategory, FilterKey } from 'App/types/filter/filterType';
import { addFilterByKeyAndValue, updateCurrentPage, updateSort } from 'Duck/liveSearch';
import DropdownPlain from 'Shared/DropdownPlain';
import SortOrderButton from 'Shared/SortOrderButton';
import { TimezoneDropdown } from 'UI';
import { capitalize } from 'App/utils';
import LiveSessionReloadButton from 'Shared/LiveSessionReloadButton';
var AUTOREFRESH_INTERVAL = .5 * 60 * 1000;
var PER_PAGE = 20;
function LiveSessionList(props) {
    var loading = props.loading, filters = props.filters, list = props.list, currentPage = props.currentPage, _a = props.metaList, metaList = _a === void 0 ? [] : _a, sort = props.sort;
    var timeoutId;
    var hasUserFilter = filters.map(function (i) { return i.key; }).includes(KEYS.USERID);
    var _b = __read(React.useState(list), 2), sessions = _b[0], setSessions = _b[1];
    var sortOptions = metaList.map(function (i) { return ({
        text: capitalize(i), value: i
    }); }).toJS();
    var displayedCount = Math.min(currentPage * PER_PAGE, sessions.size);
    var addPage = function () { return props.updateCurrentPage(props.currentPage + 1); };
    useEffect(function () {
        if (filters.size === 0) {
            props.addFilterByKeyAndValue(FilterKey.USERID, '');
        }
    }, []);
    useEffect(function () {
        if (metaList.size === 0 || !!sort.field)
            return;
        if (sortOptions[0]) {
            props.updateSort({ field: sortOptions[0].value });
        }
    }, [metaList]);
    useEffect(function () {
        var filteredSessions = filters.size > 0 ? props.list.filter(function (session) {
            var hasValidFilter = true;
            filters.forEach(function (filter) {
                if (!hasValidFilter)
                    return;
                var _values = filter.value.filter(function (i) { return i !== '' && i !== null && i !== undefined; }).map(function (i) { return i.toLowerCase(); });
                if (filter.key === FilterKey.USERID) {
                    var _userId_1 = session.userId ? session.userId.toLowerCase() : '';
                    hasValidFilter = _values.length > 0 ? (_values.includes(_userId_1) && hasValidFilter) || _values.some(function (i) { return _userId_1.includes(i); }) : hasValidFilter;
                }
                if (filter.category === FilterCategory.METADATA) {
                    var _source_1 = session.metadata[filter.key] ? session.metadata[filter.key].toLowerCase() : '';
                    hasValidFilter = _values.length > 0 ? (_values.includes(_source_1) && hasValidFilter) || _values.some(function (i) { return _source_1.includes(i); }) : hasValidFilter;
                }
            });
            return hasValidFilter;
        }) : props.list;
        setSessions(filteredSessions);
    }, [filters, list]);
    useEffect(function () {
        props.fetchLiveList();
        timeout();
        return function () {
            clearTimeout(timeoutId);
        };
    }, []);
    var onUserClick = function (userId, userAnonymousId) {
        if (userId) {
            props.addFilterByKeyAndValue(FilterKey.USERID, userId);
        }
        else {
            props.addFilterByKeyAndValue(FilterKey.USERANONYMOUSID, userAnonymousId);
        }
    };
    var onSortChange = function (e, _a) {
        var value = _a.value;
        props.updateSort({ field: value });
    };
    var timeout = function () {
        timeoutId = setTimeout(function () {
            props.fetchLiveList();
            timeout();
        }, AUTOREFRESH_INTERVAL);
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "flex mb-6 justify-between items-end" },
            React.createElement("div", { className: "flex items-baseline" },
                React.createElement("h3", { className: "text-2xl capitalize" },
                    React.createElement("span", null, "Live Sessions"),
                    React.createElement("span", { className: "ml-2 font-normal color-gray-medium" }, sessions.size)),
                React.createElement(LiveSessionReloadButton, null)),
            React.createElement("div", { className: "flex items-center" },
                React.createElement("div", { className: "flex items-center" },
                    React.createElement("span", { className: "mr-2 color-gray-medium" }, "Timezone"),
                    React.createElement(TimezoneDropdown, null)),
                React.createElement("div", { className: "flex items-center ml-6 mr-4" },
                    React.createElement("span", { className: "mr-2 color-gray-medium" }, "Sort By"),
                    React.createElement(DropdownPlain, { options: sortOptions, onChange: onSortChange, value: sort.field })),
                React.createElement(SortOrderButton, { onChange: function (state) { return props.updateSort({ order: state }); }, sortOrder: sort.order }))),
        React.createElement(NoContent, { title: "No live sessions.", subtext: React.createElement("span", null,
                "See how to ",
                React.createElement("a", { target: "_blank", className: "link", href: "https://docs.openreplay.com/plugins/assist" }, 'enable Assist'),
                " and ensure you're using tracker-assist ",
                React.createElement("span", { className: "font-medium" }, "v3.5.0"),
                " or higher."), image: React.createElement("img", { src: "/img/live-sessions.png", style: { width: '70%', marginBottom: '30px' } }), show: !loading && sessions && sessions.size === 0 },
            React.createElement(Loader, { loading: loading },
                sessions && sessions.sortBy(function (i) { return i.metadata[sort.field]; }).update(function (list) {
                    return sort.order === 'desc' ? list.reverse() : list;
                }).take(displayedCount).map(function (session) { return (React.createElement(SessionItem, { key: session.sessionId, session: session, live: true, hasUserFilter: hasUserFilter, onUserClick: onUserClick, metaList: metaList })); }),
                React.createElement(LoadMoreButton, { className: "my-6", displayedCount: displayedCount, totalCount: sessions.size, onClick: addPage })))));
}
export default withPermissions(['ASSIST_LIVE'])(connect(function (state) { return ({
    list: state.getIn(['sessions', 'liveSessions']),
    loading: state.getIn(['sessions', 'loading']),
    filters: state.getIn(['liveSearch', 'instance', 'filters']),
    currentPage: state.getIn(["liveSearch", "currentPage"]),
    metaList: state.getIn(['customFields', 'list']).map(function (i) { return i.key; }),
    sort: state.getIn(['liveSearch', 'sort']),
}); }, {
    fetchLiveList: fetchLiveList,
    applyFilter: applyFilter,
    addAttribute: addAttribute,
    addFilterByKeyAndValue: addFilterByKeyAndValue,
    updateCurrentPage: updateCurrentPage,
    updateSort: updateSort,
})(LiveSessionList));
